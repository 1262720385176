<template>
  <v-container fluid>
    <v-row>
      <ticket-info-modal ref="infoModal"></ticket-info-modal>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-row>
              <v-col cols="12">
                <v-autocomplete label="Települések" clearable multiple :items="telepulesek"
                  v-model="filters.telepulesek" :search-input.sync="telepulesSearchInput"
                  @change="telepulesSearchInput = ''" :filter="telepulesFilter">
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-select label="Állapot" multiple clearable :items="ticketStatusEnum" item-value="value"
                  item-text="text" v-model="filters.status">
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select label="Csoport" :items="ticketGroups" item-value="id" item-text="name"
                  v-model="filters.group_id" clearable>
                </v-select>
              </v-col>
              <v-col align="right">
                <v-spacer></v-spacer>
                <v-btn color="primary" width="150px" @click="onFilterClicked">
                  Szűrés
                  <v-icon right>mdi-filter</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table :headers="tableHeaders" :items="tableItems.data" :loading="loading"
              :server-items-length="tableItems.total" :options.sync="tableOptions" :page.sync="tableItems.page"
              loading-text="Hibajegyek betöltése" class="dataTable" dense hide-default-footer @click:row="onRowClicked">
              <template v-slot:item.services="{ item }">
                <ul>
                  <li v-for="service in item.services" :key="service.service_id">
                    {{ service.zip_code }}, {{ service.city }},
                    {{ service.street1 }}
                  </li>
                </ul>
              </template>

              <template v-slot:item.full_name="{ item }">
                {{ item.company_name || item.full_name }}
              </template>
              <template v-slot:item.group_id="{ item }">
                {{ getTicketGroupText(item.group_id).name || "N/A" }}
              </template>
              <template v-slot:item.status="{ item }">
                {{ getTicketStatusText(item.status).text || "N/A" }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-footer app bottom fixed padless>
      <v-card flat tile width="100%" class="text-center">
        <v-card-text>
          <v-pagination :disabled="this.loading" v-model="tableItems.page" :length="tableItems.pages" class="my-4"
            :total-visible="5"></v-pagination>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
import { API } from "../api";

import TicketInfoModal from "../components/TicketInfoModal.vue";

export default {
  components: { TicketInfoModal },
  name: "tickets",
  data: () => ({
    loading: true,
    adminUsers: [],
    tableHeaders: [
      {
        text: "Név",
        value: "full_name",
        sortable: false,
      },
      {
        text: "Státusz",
        value: "status",
        sortable: false,
      },
      {
        text: "Csoport",
        value: "group_id",
        sortable: false,
      },
      {
        text: "Végpontok",
        value: "services",
        sortable: false,
      },
      {
        text: "Tárgy",
        value: "subject",
        sortable: false,
      },
      {
        text: "Létrehozva",
        value: "created_at",
        sortable: false,
      },
    ],
    tableItems: [],
    telepulesek: [],
    ticketGroups: [],
    ticketStatusEnum: [
      {
        text: "Új",
        value: 0,
      },
      {
        text: "Nyitott",
        value: 1,
      },
      {
        text: "Folyamatban",
        value: 2,
      },
      {
        text: "Lezárt",
        value: 3,
      },
    ],
    firstLoad: true,
    tableOptions: {},
    filters: {
      telepulesek: [],
    },
    telepulesSearchInput: "",
  }),
  watch: {
    tableOptions: {
      handler() {
        if (!this.loading) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  mounted: function () {
    this.$store.commit("app/setTitle", "Hibajegyek");
    this.loadFilters();
    this.loadData();
  },
  methods: {
    toNormalForm: function (str) {
      str = str.toString();
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    loadFilters: async function () {
      await API.instance.get("/ucrm/telepulesek").then((response) => {
        this.telepulesek = response.data;
      });
      await API.instance.get("/ucrm/ticketgroups").then((response) => {
        this.ticketGroups = response.data;
      });
      await API.instance.get("/ucrm/admins").then((response) => {
        this.adminUsers = response.data;
      });
    },
    loadData: async function (filter = false) {
      this.loading = true;
      let params = await this.buildQuery();
      window.scrollTo(0, 0);
      console.log(params);
      if (filter) params.page = 1;
      await API.instance
        .get("/ucrm/tickets", { params })
        .then((response) => {
          console.log(response.data);
          this.tableItems = response.data;
          let same = true;

          for (let key in this.$route.query) {
            if (!params[key]) {
              same = false;
              break;
            }
            if (this.$route.query[key].toString() != params[key].toString()) {
              same = false;
              break;
            }
          }

          for (let key in params) {
            if (!this.$route.query[key]) {
              same = false;
              break;
            }
            if (params[key].toString() != this.$route.query[key].toString()) {
              same = false;
              break;
            }
          }
          if (!same) {
            this.$router.replace({ name: "tickets", query: params });
          }

          this.firstLoad = false;
        })
        .catch((err) => {
          if (err.response && err.response.status == 404) {
            this.tableOptions.page = 1;
            this.firstLoad = false;
            this.loadData();
          }
        })
        .finally(() => (this.loading = false));
    },
    getTicketGroupText: function (groupId) {
      return this.ticketGroups.find((el) => el.id == groupId) || "N/A";
    },
    getTicketStatusText: function (status) {
      return this.ticketStatusEnum.find((el) => el.value === status);
    },
    onFilterClicked: function () {
      this.loadData(true);
    },
    onRowClicked: function (ticket) {
      this.$refs.infoModal.open(ticket, this.adminUsers, this.ticketGroups);
    },
    buildQuery: function () {
      let params = {};

      if (this.firstLoad) {
        params = {
          ...this.$route.query,
        };

        // Rendezés
        if (params.sortBy) {
          /* Át kell konvertálni array formátumban a vuetify datatables csak így eszi meg
          Hiába nincs multi sort
          */
          this.tableOptions.sortBy = [params.sortBy];
          this.tableOptions.sortDesc = [params.order === "desc" ? true : false];
        }

        if (!params.page) {
          params.page = 1;
        }
        // Items per page
        if (params.itemsPerPage) {
          this.tableOptions.itemsPerPage = parseInt(params.itemsPerPage);
        } else {
          // LocalStorage-ból betöltjük
          this.tableOptions.itemsPerPage =
            parseInt(localStorage.getItem("ticketItemsPerPage")) || 25;
          params.itemsPerPage =
            parseInt(localStorage.getItem("ticketItemsPerPage")) || 25;
        }

        // Települések
        if (params.telepulesek) {
          this.filters.telepulesek = params.telepulesek
            .split(",")
            .map((el) => el);
        }

        // Státuszra való szűrés
        if (params.status) {
          this.filters.status = params.status
            .split(",")
            .map((el) => parseInt(el));
        } else {
          params.status = "0,1,2";
          this.filters.status = [0, 1, 2];
        }

        // keresés
        if (params.search) {
          this.filters.search = params.search;
        }

        if (params.group_id) {
          this.filters.group_id = parseInt(params.group_id);
        }
        this.firstLoad = false;
      } else {
        params = {
          ...this.$route.query,
          ...this.filters,
          ...this.tableOptions,
        };

        // Sortby csak egy elem engedélyezett!
        if (params.sortBy && params.sortBy.length > 0) {
          params.sortBy = params.sortBy[0];
        }

        if (params.sortDesc && params.sortDesc[0] === true) {
          params.order = "desc";
        } else {
          delete params.order;
        }
        // Ne kerüljön be úgy szűrési feltétel hogy az null
        for (let item in params) {
          if (!params[item]) delete params[item];
        }

        // A tömböket delimited listává kell konvertálni.
        if (params.telepulesek && params.telepulesek.length > 0) {
          params.telepulesek = params.telepulesek.toString();
        }

        if (params.status && params.status.length > 0) {
          params.status = params.status.toString();
        }
        if (params.itemsPerPage) {
          parseInt(
            localStorage.setItem("ticketItemsPerPage", params.itemsPerPage)
          );
        }
      }
      return params;
    },
    telepulesFilter: function (item, queryText, itemText) {
      const t1 = this.toNormalForm(queryText);
      const t2 = this.toNormalForm(itemText);
      return t2.toLocaleLowerCase().indexOf(t1.toLocaleLowerCase()) > -1;
    }
  },
};
</script>
