<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> Hibajegy </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <v-simple-table style="padding-top: 10px" class="properties_table">
          <tr>
            <th>Tárgy</th>
            <td>
              <a :href="ticket.ucrm_urls.ticket" target="_blank">{{
                ticket.subject
              }}</a>
            </td>
          </tr>
          <tr>
            <th>Hozzárendelt felhasználó</th>
            <td>
              {{ getUsername(ticket.assigned_user_id) }}
            </td>
          </tr>
          <tr>
            <th>Hozzárendelt csoport</th>
            <td>
              {{ getTicketGroupText(ticket.group_id).name || "N/A" }}
            </td>
          </tr>
          <tr>
            <th>Állapot</th>
            <td>
              {{ getTicketStatusText(ticket.status).text }}
            </td>
          </tr>
          <tr>
            <th>Ügyfél</th>
            <td>
              <a :href="ticket.ucrm_urls.client" target="_blank">{{
                ticket.company_name || ticket.full_name
              }}</a>
            </td>
          </tr>
          <tr>
            <th>Végpontok</th>
            <td>
              <ul>
                <li
                  v-for="service in ticket.services"
                  :key="service.service_id"
                >
                  <a :href="service.url" target="_blank"
                    >{{ service.zip_code }}, {{ service.city }},
                    {{ service.street1 }}</a
                  >
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>Létrehozva</th>
            <td>
              {{ ticket.created_at }}
            </td>
          </tr>
          <tr>
            <th>Utolsó aktivitás</th>
            <td>
              {{ ticket.last_activity }}
            </td>
          </tr>
        </v-simple-table>
      </v-card-text>
      <div
        class="grey lighten-1"
        style="padding-top: 10px; padding-bottom: 10px"
      >
        <v-card
          v-for="activity in ticketActivity"
          :key="activity.id"
          style="margin-bottom: 10px; margin-left: 5px; margin-right: 5px"
        >
          <v-card-subtitle
            ><b>{{ getUsername(activity.userId) }}</b> <v-spacer></v-spacer
            >{{ activity.createdAt }}
          </v-card-subtitle>
          <v-card-text>
            <template v-if="activity.type == 'comment'">
              {{ activity.comment.body || "Empty body" }}
            </template>
            <template v-else-if="activity.type == 'assignment'">
              <b>{{ getUsername(activity.userId) }}</b> hozzárendelte a
              hibajegyet
              <b>{{ getUsername(activity.assignment.assignedUserId) }}</b>
              felhasználóhoz
            </template>
            <template v-else-if="activity.type == 'status_change'">
              {{ getUsername(activity.userId) }} megváltoztatta a hibajegy
              állapotát:
              <b>{{
                getTicketStatusText(activity.statusChange.status).text
              }}</b>
            </template>
            <template v-else-if="activity.type == 'assignment_group'">
              {{ getUsername(activity.userId) }} hozzárendelte következő
              csoporthoz:
            </template>
            <template v-else>
              Valószínűleg csoport hozzárendelés lenne, de az UCRM API bejegyzés
              üres bejegyzést ad vissza ez egy UCRM bug.
              {{ activity }}
            </template>
          </v-card-text>
        </v-card>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { API } from "../api";
export default {
  name: "TicketInfoModal",
  data: () => ({
    loading: true,
    ticketActivity: {},
    ticket: {
      ucrm_urls: {},
      services: [],
    },
    adminUsers: [],
    ticketId: null,
    resolve: null,
    reject: null,
    dialog: false,
    ticketStatusEnum: [
      {
        text: "Új",
        value: 0,
      },
      {
        text: "Nyitott",
        value: 1,
      },
      {
        text: "Folyamatban",
        value: 2,
      },
      {
        text: "Lezárt",
        value: 3,
      },
    ],
    ticketGroups: [],
  }),
  methods: {
    open: async function (ticket, adminUsers, ticketGroups) {
      this.ticket = ticket;
      this.adminUsers = adminUsers;
      this.ticketGroups = ticketGroups;
      await API.instance
        .get(`/ucrm/ticketactivity/${ticket.ticket_id}`)
        .then((response) => (this.ticketActivity = response.data));

      this.dialog = true;
      this.loading = false;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    close: function () {
      this.resolve(null);
      this.dialog = false;
    },
    getTicketGroupText: function (groupId) {
      return this.ticketGroups.find((el) => el.id == groupId) || "N/A";
    },
    getUsername: function (id) {
      const usr = this.adminUsers.find((el) => el.id === id);

      if (usr) {
        return `${usr.firstName} ${usr.lastName}`;
      } else {
        return "N/A";
      }
    },
    getTicketStatusText: function (status) {
      return this.ticketStatusEnum.find((el) => el.value === status);
    },
  },
};
</script>
<style>
.v-dialog > .v-card > .v-card__text {
  padding: 0px;
}
</style>
